export enum Slug {
  HOME = "/",
  ABOUT_US = "/about-us",
  COMPARE = "/compare-projects",
  CONTACT_US = "/contact-us",
  LATEST_MORTGAGE_RATES = "/latest-mortgage-rates",
  LATEST_PROMOTIONS = "/latest-promotion",
  MORTGAGE_CALCULATOR = "/mortgage-calculator",
  PRIVACY_POLICY = "/privacy-policy",
  PROJECTS = "/projects",
  PROMOTION = "/promotion",
  TOP_TOP_SOON = "/top-soon",
  UPCOMING_LAUNCHES = "/upcoming-launches",
  VIRTUAL_TOURS = "/virtual-tours",
  PROJECT = "/project",
  PROPERTY = "/property",
  PARTNER_US = "/partner-us",
  RCR = "/rcr",
  CCR = "/ccr",
  OCR = "/ocr",
  NOT_FOUND = "/404",
  PRICE_LIST = "/price-list",
  SECTIONS = "/sections",
  LAYOUT = "/layouts",
  FEATURED_LAUNCHES = "/featured-launches",
  ENQUIRY_RECEIVED = "/enquiry-received",
  THANK_YOU = "/thank-you",
  LDP_EDITOR = "/editor/landing-page",
  EDITOR_PREVIEW = "/editor/preview",
}


export enum ActionSlug {
  ENQUIRY_SUBMIT = "/action/enquiry-submit",
  LDP_EDITOR = "/action/editor/landing-page",
  UPLOAD_FILE = '/action/upload-file',
  SITE_CONTENT = '/action/site-content'
}

export enum LoaderSlug {
  SITE_CONTENT = '/loader/site-content',
  LANDING_PAGE_DATA = '/loader/landing-page-data'
}
